$primaryColor: #152354;
$primaryText: #fff;
$secondaryColor: #0b122b;
$secondaryText: #fff;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #e6e6e6 !important; */
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .shadow-effect {
    box-shadow: 0 2px 4px rgba(11, 18, 43, 0.4);
  }

  .shadow-effect-bottom {
    box-shadow: inset 0 -10px 10px -10px rgba(11, 18, 43, 0.4);
  }
  .shadow-effect-top {
    box-shadow: 0 10px 10px -10px rgba(11, 18, 43, 0.4);
  }
  
  
  .color-primary {
    background-color: $primaryColor;
    color: $primaryText;
  }
  
  .color-secondary {
    background-color: $secondaryColor;
    color: $secondaryText;
  }

  .main-nav {
    background-color: $primaryColor;
    color: $primaryText;
    text-align: center;
  }

  .main-nav a {
    // float: right;
    // display: block;
    color: $primaryText;
    text-align: center;
    padding: 14px 16px;
    margin: auto;
    // text-decoration: none;
    font-size: 15px;
    border-bottom: 3px solid transparent;
  }

  .main-nav a:hover {
    border-bottom: $primaryText;
    border-bottom: 3px;
    border-bottom: 3px solid;
  }

  .main-nav a:active {
    border-bottom: $primaryText;
    border-bottom: 3px;
  }
  
  #nav-dropdown{
    color: $primaryText !important;
  }
  
  .dropdown-menu {
    background-color: $primaryColor !important;
  }
  
  .dropdown-item {
    color:$primaryText !important;
  }
  
  .container-leaf {
    background-image: url('../images/dark_leaf.png');
    color: #a5a5a5;
  }
  
  .container-leaf-light {
    background-image: url('../images/light_leaf.png');
  }
  
  .text-green {
    color: #9fc612;
  }
  
  .panel-border {
    border: 1px solid #c4c4c4;
  }
  
  a {
    color: $primaryText !important;
    text-decoration: none !important;
  }
  
  a:hover {
    color: $primaryText !important;
  }
  
  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  
  #logo {
    max-height: 75px !important;
  }
  
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
  }
  
  .navbar-toggler:active {
    border-width: 3px;
    border-color: $primaryText !important;
  }
  .navbar-toggler:focus {
    border-width: 3px;
    border-color: $primaryText !important;
  }
  
  
  
  